// This file was automatically generated from widget.followers.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Widget == 'undefined') { Nightloop.Templates.Widget = {}; }


Nightloop.Templates.Widget.Followers = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="followers-component">', (opt_data.title) ? '<div class=\'title\'>Followers</div>' : '', '<span class="frozen-followers-area">');
  var followerList8016 = opt_data.frozenFollowers;
  var followerListLen8016 = followerList8016.length;
  for (var followerIndex8016 = 0; followerIndex8016 < followerListLen8016; followerIndex8016++) {
    var followerData8016 = followerList8016[followerIndex8016];
    output.append('<span class="pic">', soy.$$escapeHtml(followerData8016.initials), '<em class=\'name\'>', soy.$$escapeHtml(followerData8016.full_name), '</em></span>');
  }
  output.append('</span><span class="followers-area">');
  var followerList8024 = opt_data.followers;
  var followerListLen8024 = followerList8024.length;
  for (var followerIndex8024 = 0; followerIndex8024 < followerListLen8024; followerIndex8024++) {
    var followerData8024 = followerList8024[followerIndex8024];
    output.append('<span class="pic">', soy.$$escapeHtml(followerData8024.initials), '<span class="del follower-remove" fid="', soy.$$escapeHtml(followerData8024.id), '">x</span>', (opt_data.includeInput) ? '<input type="hidden" name="fid" value="' + soy.$$escapeHtml(followerData8024.id) + '" />' : '', '<em class=\'name\'>', soy.$$escapeHtml(followerData8024.full_name), '</em></span>');
  }
  output.append('</span><span id="insert-followers" class="add"><img src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/add_2x.png" /><select id="followers-select"></select></span></div>');
  return opt_sb ? '' : output.toString();
};
