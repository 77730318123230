// This file was automatically generated from admin.neighborhood.list.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.NeighborhoodList = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.NeighborhoodListBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.NeighborhoodListBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="neighborhoodlist-page"><div class="content-header">Neighborhoods</div><div class="right vpadding"><a id="neigh-add-link" class="ajaxify" href="/admin/neighborhood/edit/">+ create new</a></div>');
  var muni_neighborhoodsList1190 = opt_data.content.neighborhoods_by_muni;
  var muni_neighborhoodsListLen1190 = muni_neighborhoodsList1190.length;
  for (var muni_neighborhoodsIndex1190 = 0; muni_neighborhoodsIndex1190 < muni_neighborhoodsListLen1190; muni_neighborhoodsIndex1190++) {
    var muni_neighborhoodsData1190 = muni_neighborhoodsList1190[muni_neighborhoodsIndex1190];
    output.append('<div class="entity-list-header">', soy.$$escapeHtml(muni_neighborhoodsData1190.muni_name), '</div>');
    var neighborhoodList1194 = muni_neighborhoodsData1190.neighborhoods;
    var neighborhoodListLen1194 = neighborhoodList1194.length;
    for (var neighborhoodIndex1194 = 0; neighborhoodIndex1194 < neighborhoodListLen1194; neighborhoodIndex1194++) {
      var neighborhoodData1194 = neighborhoodList1194[neighborhoodIndex1194];
      output.append((neighborhoodIndex1194 % 2 == 0) ? '<div class="container entity-record row-even"><a class="ajaxify neigh-edit-link" href="/admin/neighborhood/edit/' + soy.$$escapeHtml(neighborhoodData1194.id) + '">' + soy.$$escapeHtml(neighborhoodData1194.name) + '</a></div>' : '<div class="container entity-record row-odd"><a class="ajaxify neigh-edit-link" href="/admin/neighborhood/edit/' + soy.$$escapeHtml(neighborhoodData1194.id) + '">' + soy.$$escapeHtml(neighborhoodData1194.name) + '</a></div>');
    }
    output.append('<div class="right vpadding"><a class="ajaxify" href="/admin/neighborhood/edit/">+ create new</a></div>');
  }
  output.append('</div>');
  return opt_sb ? '' : output.toString();
};
