// This file was automatically generated from admin.ssoorg.edit.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Admin == 'undefined') { Nightloop.Templates.Admin = {}; }


Nightloop.Templates.Admin.SSOOrgEdit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Admin.Layout(soy.$$augmentData(opt_data, {is_sevenrooms_admin: opt_data.content.is_sevenrooms_admin, DynamicTemplate: 'Nightloop.Templates.Admin.SSOOrgEditBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Admin.SSOOrgEditBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-admin-ssoorg-edit"><div><a class="ajaxify" href="/admin/ssoorg/list">&lt;&lt; back to all</a></div><div class="content-header">SSO Org ', (opt_data.content.sso_org) ? 'Edit' : 'Create', '</div>', (opt_data.content.sso_org) ? '<form action="/admin/ssoorg/' + soy.$$escapeHtml(opt_data.content.sso_org.id) + '/edit" method="post" class="ajaxify">' : '<form action="/admin/ssoorg/create" method="post" class="ajaxify">', (opt_data.content.error_message) ? '<div class="error-message">' + soy.$$escapeHtml(opt_data.content.error_message) + '</div>' : '', (opt_data.content.message) ? '<div class="gold-message">' + soy.$$escapeHtml(opt_data.content.message) + '</div>' : '', '<table style="width:100%;"><tr><td class="form-label">SSO Org Unique Name:</td><td><input type="text" name="', soy.$$escapeHtml(opt_data.content.form.fields.name.name), '" value="', soy.$$escapeHtml(opt_data.content.form.fields.name.value), '"/></td></tr><tr><td class="form-label">SSO Setup URL to send to Clients (ACS URL):</td><td><div id="acs_url"></div></td></tr><tr><td class="form-label">Web SSO Login URL:</td><td><div id="sso_url"></div></td></tr><tr><td class="form-label">SSO Single Logout URL:</td><td><div id="slo_url"></div></td></tr><tr><td class="form-label">Validation Type:</td><td><select name="', soy.$$escapeHtml(opt_data.content.form.fields.validation_type.name), '">');
  var validation_typeList1484 = opt_data.content.validation_types;
  var validation_typeListLen1484 = validation_typeList1484.length;
  for (var validation_typeIndex1484 = 0; validation_typeIndex1484 < validation_typeListLen1484; validation_typeIndex1484++) {
    var validation_typeData1484 = validation_typeList1484[validation_typeIndex1484];
    output.append('<option value="', soy.$$escapeHtml(validation_typeData1484.type), '" ', (validation_typeData1484.type == opt_data.content.form.fields.validation_type.value) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(validation_typeData1484.display), '</option>');
  }
  output.append('</select></td></tr><tr id="fallback-email"><td class="form-label">Fallback Email Domain:</td><td><input type="text" name="', soy.$$escapeHtml(opt_data.content.form.fields.fallback_email_domain.name), '" value="', soy.$$escapeHtml(opt_data.content.form.fields.fallback_email_domain.value), '" /></td></tr><tr><td class="form-label">Identity Provider:</td><td><select name="', soy.$$escapeHtml(opt_data.content.form.fields.identity_provider.name), '">');
  var identity_providerList1502 = opt_data.content.identity_providers;
  var identity_providerListLen1502 = identity_providerList1502.length;
  for (var identity_providerIndex1502 = 0; identity_providerIndex1502 < identity_providerListLen1502; identity_providerIndex1502++) {
    var identity_providerData1502 = identity_providerList1502[identity_providerIndex1502];
    output.append('<option value="', soy.$$escapeHtml(identity_providerData1502), '" ', (identity_providerData1502 == opt_data.content.form.fields.identity_provider.value) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(identity_providerData1502), '</option>');
  }
  output.append('</select></td></tr><tr><td class="form-label">Metadata URL:</td><td><input type="text" name="', soy.$$escapeHtml(opt_data.content.form.fields.metadata_url.name), '" value="', soy.$$escapeHtml(opt_data.content.form.fields.metadata_url.value), '" /></td></tr><tr><td class="form-label">Issuer:</td><td><input type="text" name="', soy.$$escapeHtml(opt_data.content.form.fields.issuer.name), '" value="', soy.$$escapeHtml(opt_data.content.form.fields.issuer.value), '" /></td></tr><tr><td class="form-label">Certificate:</td><td><textarea name="', soy.$$escapeHtml(opt_data.content.form.fields.certificate.name), '">', soy.$$escapeHtml(opt_data.content.form.fields.certificate.value), '</textarea></td></tr><tr><td class="form-label">Email Domain Whitelist (Comma Delimited and required unless using superhero validation):</td><td><textarea name="', soy.$$escapeHtml(opt_data.content.form.fields.email_domain_whitelist.name), '">', soy.$$escapeHtml(opt_data.content.form.fields.email_domain_whitelist.value), '</textarea></td></tr>');
  if (opt_data.content.sso_org && opt_data.content.sso_org.is_sevenrooms) {
    output.append('<tr><td class="form-label">User Access Flags</td><td>Sevenrooms, ALL access flags are whitelisted<ul>');
    var access_flagList1533 = opt_data.content.access_flags;
    var access_flagListLen1533 = access_flagList1533.length;
    for (var access_flagIndex1533 = 0; access_flagIndex1533 < access_flagListLen1533; access_flagIndex1533++) {
      var access_flagData1533 = access_flagList1533[access_flagIndex1533];
      output.append('<li>', soy.$$escapeHtml(access_flagData1533), '</li>');
    }
    output.append('</ul><input type="hidden" name="', soy.$$escapeHtml(opt_data.content.form.fields.allowed_user_access_flags.name), '" value="', soy.$$escapeHtml(opt_data.content.form.fields.allowed_user_access_flags.value), '" ></td></tr>');
  } else {
    output.append('<tr><td class="form-label">Allowed User Access Flags (Common only for partner SSO orgs):</td><td><textarea name="', soy.$$escapeHtml(opt_data.content.form.fields.allowed_user_access_flags.name), '">', soy.$$escapeHtml(opt_data.content.form.fields.allowed_user_access_flags.value), '</textarea></td></tr>');
  }
  output.append('<tr><td class="form-label">&nbsp;</td><td><input type="submit" value="Save" name="submit" /></td></tr></table></form></div><script type="text/javascript">\n        $(document).ready(function() {\n            function updateSSOUrls($sso_name, $identity_provider) {\n                var $acs_url = $(\'#acs_url\');\n                var $sso_url = $(\'#sso_url\');\n                var $slo_url = $(\'#slo_url\');\n\n                var env_prefix = window.location.origin\n                var sso_name_val = $sso_name.val().trim();\n                var identity_provider_val = $identity_provider.val().trim();\n\n                $acs_url.text([env_prefix, \'sso\', identity_provider_val, sso_name_val].join(\'/\'));\n                $sso_url.text([env_prefix, \'single_sign_on\', \'login\', sso_name_val].join(\'/\'));\n                $slo_url.text([env_prefix, \'sso\', \'logout\', sso_name_val].join(\'/\'));\n            }\n\n            var $sso_name = $(\'input[name=name]\');\n            var $identity_provider = $(\'select[name=identity_provider]\');\n\n            updateSSOUrls($sso_name, $identity_provider);\n\n            $sso_name.on(\'input\', function () {\n                updateSSOUrls($sso_name, $identity_provider);\n            })\n\n            $identity_provider.on(\'change\', function () {\n                updateSSOUrls($sso_name, $identity_provider);\n            })\n\n            var $validation_type_select = $(\'select[name=validation_type]\');\n            var $fallback_email_field = $(\'#fallback-email\');\n            var REQUIRE_FALLBACK_EMAIL_TYPES = [\'USERNAME_EMAIL_WITH_FALLBACK\'];\n            $validation_type_select.change(function() {\n                var validation_type_val = $validation_type_select.val();\n                $fallback_email_field.toggle($.inArray(validation_type_val, REQUIRE_FALLBACK_EMAIL_TYPES) !== -1);\n            }).change();\n        })\n        <\/script>');
  return opt_sb ? '' : output.toString();
};
